<template>
  <div>
    <div v-if="magicLinkSent">
      <BaseCard class="text-center shadow-lg">
        <h1 class="text-3xl">
          Check your inbox
        </h1>
        <Mailbox class="w-56 text-center mx-auto my-6"/>
        <p class="text-lg">
          We’ve emailed a link to <span class="font-semibold text-xl">{{ formResponses.email }}</span>
        </p>
      </BaseCard>
    </div>
    <div
      v-else
      class="bg-white p-6 shadow-lg"
    >
      <h1 class="text-2xl md:text-3xl xl:text-4xl">
        Sign in to <br />Picked's Assessment Centre
      </h1>

      <div class="mt-8 mb-4">
        <SignInWithGoogle
          @success="signInWithGoogleSuccess"
          @error="signInWithGoogleError"
        />
        <p
          v-if="signInWithGoogleErrorMessage"
          class="text-red-500 text-xs text-left mt-2"
        >
          {{ signInWithGoogleErrorMessage }}
        </p>
      </div>

      <form
        class="mt-8"
        novalidate
        @submit.prevent
      >
        <label class="block mt-6 relative">
          <span class="text-gray-800">Email address</span>
          <input
            v-model="$v.formResponses.email.$model"
            type="email"
            class="form-input mt-1 block w-full bg-gray-100"
            placeholder="Email address"
          >
        </label>
        <ErrorsInline v-if="errorMessage">
          {{ errorMessage }}
        </ErrorsInline>

        <section>
          <BaseButton
            class="mt-8"
            :loading="isProcessing"
            :disabled="!canSubmitEmail"
            @click="submit"
          >
            Send magic link
          </BaseButton>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SignInWithGoogle from '@components/Auth/SignInWithGoogle'
import ErrorsInline from '@components/ErrorsInline'
import Mailbox from '@components/Vectors/Mailbox'

import candidateWizardApi from '@api/candidateWizard'

import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { setLocalStorage } from '@utils/localStorage'

export default {
  components: {
    SignInWithGoogle,
    ErrorsInline,
    Mailbox
  },

  mixins: [validationMixin],

  data() {
    return {
      isProcessing: false,
      errorMessage: null,
      signInWithGoogleErrorMessage: null,
      magicLinkSent: false,
      formResponses: {
        email: null
      }
    }
  },

  computed: {
    /**
     * @return {Boolean}
     */
    canSubmitEmail() {
      return !this.$v.formResponses.$invalid
    },

    /**
     * @return {String}
     */
    jobId() {
      return this.$route.params.job
    }
  },

  validations: {
    formResponses: {
      email: {
        required,
        email
      }
    }
  },

  created() {
    this.getJob()
  },

  methods: {
    getJob() {
      if (!this.jobId) {
        return
      }

      return candidateWizardApi.job(this.jobId)
        .then(response => {
          this.job = response.data
        })
        .catch(error => {
          throw error
        })
    },

    submit() {
      this.isProcessing = true
      this.errorMessage = null

      candidateWizardApi.signIn(this.formResponses.email, this.job.uuid)
        .then(response => {
          if (response.data.success) {
            this.magicLinkSent = true
            this.isProcessing = false

            return
          }
          throw new Error('Response did not contain success')
        })
        .catch(error => {
          this.isProcessing = false
          console.log(error.response)
          if (error.response && error.response.data && error.response.data.errors) {
            this.errorMessage = error.response.data.errors
            return
          }
          throw error
        })
    },

    signInWithGoogleSuccess(idToken) {
      this.loading = true
      this.errors = false

      const candidate = {
        idToken: idToken,
        jobUuid: this.job.uuid,
        invitationCode: this.invitationParam
      }

      return candidateWizardApi.googleSignUp(candidate)
        .then(response => {
          this.loading = false
          setLocalStorage('candidateWizard.uuid', response.data.uuid)
          setLocalStorage('candidateWizard.password', response.data.secret)
          this.$router.push({
            name: 'candidate-wizard-dashboard',
            query: {
              job: this.jobId
            }
          })
        })
        .catch(error => {
          this.loading = false
          if (error.response && error.response.data.errors) {
            console.error(error)
            this.errors = error.response.data.errors
            return
          }
          throw error
        })
    },

    signInWithGoogleError(error) {
      if (error.error === 'popup_closed_by_user') {
        return
      }
      this.signInWithGoogleErrorMessage = error.error
    }
  }
}
</script>
