<template>
  <div class="w-full max-w-xl relative mx-auto py-8 sm:py-16 px-4">
    <CandidateWizardSignIn />
  </div>
</template>

<script>
import CandidateWizardSignIn from '@components/CandidateWizard/CandidateWizardSignIn'

export default {
  components: {
    CandidateWizardSignIn
  },

  page() {
    return {
      title: 'Candidate Sign In',
      meta: [
        { name: 'description', content: `Sign in to ${process.env.VUE_APP_SATELLITE_NAME}` },
        { rel: 'canonical', href: `${process.env.VUE_APP_SPLASH_URL}${this.$route.fullPath}` },

        { property: 'og:title', content: 'Candidate Sign In' },
        { property: 'og:site_name', content: process.env.VUE_APP_SATELLITE_NAME },
        { property: 'og:url', content: `${process.env.VUE_APP_SPLASH_URL}${this.$route.fullPath}` },
        { property: 'og:image', content: `${process.env.VUE_APP_SPLASH_URL}/img/tc-logo-white.png` },
        { property: 'og:type', content: 'website' },
        { property: 'og:description', content: `Sign in to ${process.env.VUE_APP_SATELLITE_NAME}` },

        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: 'Candidate Sign In' },
        { name: 'twitter:description', content: `Candidate Sign in to ${process.env.VUE_APP_SATELLITE_NAME}` }
      ]
    }
  }
}
</script>
